<template>
  <div class="card">
    <img
      style="height: 148px; object-fit: cover"
      :src="data.banner"
      alt="Banner"
      srcset=""
    />

    <b-form-checkbox
      v-if="data.is_approved"
      class="program-toggle"
      :value="data.is_approved"
      :checked="!data.is_disabled"
      @change="toggleProgramStatus(data._id, data.is_disabled)"
      switch
    ></b-form-checkbox>
    <h6
      style="
        margin-top: -44px;
        padding-left: 12px;
        padding-top: 4px;
        padding-right: 4px;
        height: 44px;

        background-color: rgba(0, 0, 0, 0.5);
        font-weight: 500;
        font-size: 0.9rem;
      "
    >
      <router-link
        :to="{
          name: 'viewProgramDetails',
          query: { id: data._id },
        }"
      >
        <div
          class="status-dot"
          :style="
            data.is_approved
              ? { 'background-color': data.is_disabled ? '#ff2424' : '#74fd74' }
              : { 'background-color': 'orange' }
          "
        ></div>
        <span style="color: #fff !important">{{
          data.name | truncate(32)
        }}</span>
      </router-link>
    </h6>
    <div class="pb-3 pl-3">
      <div>
        <strong>{{ data.tribe_id.name }}</strong>
        <div v-if="iscurrentUserAdmin">
          {{ data.vendor_id.company_name }}
        </div>
      </div>
      <div
        v-if="!iscurrentUserAdmin && !data.is_approved"
        class="verification-label"
      >
        Pending for verification
      </div>
    </div>
  </div>
</template>

<script>
import { program } from "@/config/api/programs";
import capitalizeFirstLetter from "@/utils/capitalize";
export default {
  props: ["programData"],
  data() {
    return {
      capitalizeFirstLetter,
    };
  },
  computed: {
    data() {
      return this.programData;
    },

    userType() {
      return this.$store.getters["auth/userType"];
    },

    iscurrentUserAdmin() {
      return this.$store.getters["auth/userType"] === "admin";
    },
  },
  methods: {
    toggleProgramStatus(id, is_disabled) {
      this.$bvModal
        .msgBoxConfirm(
          `Do you want to ${is_disabled ? "enable" : "disable"}?`,
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((result) => {
          if (result) {
            const data = {
              type: capitalizeFirstLetter(this.userType),
              programId: id,
              is_disabled: !is_disabled,
            };
            const api = program.disableProgram;
            api.data = data;
            this.changingStatus = true;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Program status updated.", {
                  title: "Program status",
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.$bvToast.toast("Program status update failed!.", {
                  title: "Program status",
                  variant: "danger",
                  solid: true,
                });
                this.$emit("reloadData");
              })
              .finally(() => {
                this.changingStatus = false;
              });
          } else {
            this.$emit("reloadData");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$emit("reloadData");
        });
    },
  },
};
</script>

<style>
.verification-label {
  background-color: orange;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  padding: 2px;
  text-align: center;
  font-weight: 200;
}
.program-toggle {
  position: absolute;
  top: 4px;
  right: 0;
}
.status-dot {
  width: 12px;
  height: 12px;

  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}
</style>
