<template>
  <Layout>
    <div class="row">
      <!-- <div class="col-md-4 mb-2">
        <b-form-select
          v-if="iscurrentUserAdmin"
          @change="tribeFilter"
          :options="tribesOptions"
          :value="null"
        ></b-form-select>
      </div> -->
      <div class="col-md-7 mb-2">
        <b-form-select
          v-if="iscurrentUserAdmin"
          @change="programFilter"
          :options="vendorOptions"
          :value="null"
        ></b-form-select>
      </div>
      <div class="col-md-1 mb-2">
        <b-button v-if="iscurrentUserAdmin" @click="reloadData"
          ><i class="ri-refresh-line"></i
        ></b-button>
      </div>
      <div class="col-md-4 mb-2">
        <div class="right-align mb-2">
          <b-button variant="primary" @click="addProgram">
            <i class="fas fa-plus"></i><span class="pl-2">Program</span>
          </b-button>
        </div>
      </div>
    </div>

    <div v-if="iscurrentUserAdmin">
      <b-tabs v-on:activate-tab="tabChanged">
        <b-tab title="Aprroved" active>
          <div
            style="text-align: center; margin: 28px"
            v-if="loadingApprovedProgram"
          >
            <pulse-loader color="#e83e8c" />
          </div>
          <div v-else>
            <div v-if="!approvedProgram.length" class="text-center my-5">
              No Programs
            </div>
            <div class="row" v-else>
              <div
                class="col-sm-4"
                v-for="program in approvedProgram"
                :key="program._id"
              >
                <program-card
                  v-on:reloadData="loadAllProgram"
                  :programData="program"
                />
              </div>
              <div class="d-flex justify-content-end col-12">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  pills
                  @change="loadApprovedProgram"
                ></b-pagination>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Pending">
          <div
            style="text-align: center; margin: 28px"
            v-if="loadingPendingProgram"
          >
            <pulse-loader color="#e83e8c" />
          </div>
          <div v-else>
            <div v-if="!pendingProgram.length" class="text-center my-5">
              No Programs
            </div>
            <div class="row" v-else>
              <div
                class="col-sm-4"
                v-for="program in pendingProgram"
                :key="program._id"
              >
                <program-card :programData="program" />
              </div>
              <div class="d-flex justify-content-end col-12">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div v-else>
      <div style="text-align: center; margin: 28px" v-if="loading">
        <pulse-loader color="#e83e8c" />
      </div>
      <div v-else>
        <div v-if="!programs.length" class="text-center my-5">No Programs</div>
        <div class="row" v-else>
          <div class="col-sm-4" v-for="program in programs" :key="program._id">
            <program-card
              v-on:reloadData="loadAllProgram"
              :programData="program"
            />
          </div>
          <div class="d-flex justify-content-end col-12">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { program } from "@/config/api/programs";
import Layout from "@/views/layouts/main";
import ProgramCard from "@/components/program/program-card";
export default {
  data() {
    return {
      programs: [],
      pendingProgram: [],
      approvedProgram: [],
      loadingApprovedProgram: false,
      loadingPendingProgram: false,
      loading: false,

      currentTab: 0,
      rows: 0,
      perPage: 12,

      currentVendor: null,
      currentTribe: null,
      currentPage: 1,

      tribesOptions: [],
      vendorOptions: [],
    };
  },
  components: {
    Layout,
    ProgramCard,
  },
  created() {
    this.loadAllProgram();
    this.loadAllTribeAndVendors();
  },
  methods: {
    loadAllProgram() {
      if (this.$store.getters["auth/userType"] === "admin") {
        this.loadApprovedProgram();
      } else {
        const api = program.getProgramForVendor;
        this.loading = true;
        this.generateAPI(api)
          .then((res) => {
            this.programs = res.data.programs;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    loadAllTribeAndVendors() {
      const api = program.getAllVendorsAndTribes;

      this.generateAPI(api)
        .then((res) => {
          this.tribesOptions = res.data.tribes.map((item) => {
            return {
              text: item.name,
              value: item._id,
            };
          });
          this.tribesOptions.push({
            text: "Please choose a tribe",
            value: null,
          });

          this.vendorOptions = res.data.vendors.map((item) => {
            return {
              text: item.company_name,
              value: item._id,
            };
          });
          this.vendorOptions.push({
            text: "Please choose a vendor",
            value: null,
          });
        })
        .finally(() => {});
    },
    loadPendingProgram(page = 1) {
      const api = program.getAllFilteredForDash;
      this.currentPage = page;
      api.params = {
        approved: 0,
        vendorId: this.currentVendor,
        tribeId: this.currentTribe,

        page: page,
        perPage: this.perPage,
      };
      this.loadingPendingProgram = true;
      this.generateAPI(api)
        .then((res) => {
          this.pendingProgram = res.data.docs;
          this.rows = res.data.totalDocs;
        })
        .finally(() => {
          this.loadingPendingProgram = false;
        });
    },
    loadApprovedProgram(page = 1) {
      const api = program.getAllFilteredForDash;
      this.currentPage = page;
      api.params = {
        approved: 1,
        vendorId: this.currentVendor,
        tribeId: this.currentTribe,
        page: page,
        perPage: this.perPage,
      };
      this.loadingApprovedProgram = true;
      this.generateAPI(api)
        .then((res) => {
          this.approvedProgram = res.data.docs;
          this.rows = res.data.totalDocs;
        })
        .finally(() => {
          this.loadingApprovedProgram = false;
        });
    },
    addProgram() {
      this.$router.push({ name: "addProgram" });
    },
    tabChanged(c) {
      this.currentTab = c;
      if (c === 0) {
        this.loadPendingProgram();
      } else {
        this.loadApprovedProgram();
      }
    },
    tribeFilter(ele) {
      this.currentVendor = null;
      if (ele) {
        this.currentTribe = ele;
        if (this.currentTab === 0) {
          this.loadPendingProgram();
        } else {
          this.loadApprovedProgram();
        }
      }
    },
    programFilter(ele) {
      this.currentTribe = null;
      if (ele) {
        this.currentVendor = ele;
        if (this.currentTab === 0) {
          this.loadPendingProgram();
        } else {
          this.loadApprovedProgram();
        }
      }
    },
    reloadData() {
      this.currentTribe = null;
      this.currentVendor = null;
      if (this.currentTab === 0) {
        this.loadPendingProgram();
      } else {
        this.loadApprovedProgram();
      }
    },
  },
  computed: {
    iscurrentUserAdmin() {
      return this.$store.getters["auth/userType"] === "admin";
    },
  },
};
</script>

<style>
.tab-content {
  padding: 12px;
  min-height: 180px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
</style>
